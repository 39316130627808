import React, { useState, props } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useForm } from "react-hook-form";
import { collection, addDoc, setDoc, doc } from "firebase/firestore";
import { db } from "services/firebaseConfig";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import ModalCurriculo from "components/features/ModalCurriculo";
import { mask } from "remask";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Message = tw.span`text-2xl sm:text-2xl pb-5 `;

const FormContainer = styled.div`
  ${tw`p-10 mt-20 sm:p-12 md:p-16  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-2xl sm:text-2xl pb-5 font-light`}
  }
  input:not(input[type="radio"]),textarea, select, option {
    ${tw`w-full bg-transparent text-white text-base font-medium tracking-wide border-b-2 py-2 text-white hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-white`}
    }
  }
`;


const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-4/12 flex flex-col pl-10`;
const ColumnFull = tw.div`sm:w-full flex flex-col pl-10`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const LabelRadio = tw.label`pr-3 inline-block py-2 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const Radio = tw.input`w-auto`;
const Option = tw.option``;
const Select = tw.select``;
const Errors = tw.span`text-white text-xs absolute bottom-0 left-0 inline-block`;


const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100  rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`
const SvgDotPattern2 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-secondary-500 fill-current w-24`

export default (props) => {
  const [step, setStep] = useState(0)
  const validation = [Yup.object().shape({
    nome: Yup.string().required('Por favor, preencha o nome'),
    nascimento: Yup.string().required('Por favor, preencha a data de Nascimento').min(10, 'Preencha a data de nascimento completa'),
    documento: Yup.string().required('Por favor, preencha o CPF').min(14, 'Preencha o CPF completo'),
    celular: Yup.string().required('Por favor, preencha o celular').min(14, 'Preencha o celular complet0'),
    rg: Yup.string().required('Por favor, preencha o RG').min(12, 'Preencha o RG completo'),
    estadoCivil: Yup.string().required('Por favor, selecione o Estado Civil'),
    atuacao1: Yup.string().required('Por favor, selecione a principal Área de atuação'),
    
    cnh: Yup.bool().required('Por favor, selecione uma opção'),



  }),
  Yup.object().shape({
    rua: Yup.string().required('Por favor, preencha a Rua'),
    bairro: Yup.string().required('Por favor, preencha o Bairro'),
    cep: Yup.string().required('Por favor, preencha o CEP').min(9, 'Preencha o CEP completo'),
    estado: Yup.string().required('Por favor, preencha o estado'),
    numero: Yup.string().required('Por favor, preencha o Número'),
    cidade: Yup.string().required('Por favor, selecione a Cidade'),
    
  })]
  
  const optionsForm = { resolver: yupResolver(validation[step]), mode: "onBlur" }
  const { register, watch , handleSubmit, setValue, formState } = useForm(optionsForm);
  const { errors } = formState
  const [cadastrado, setCadastrado] = useState(false);
  const [numCNH, setNumCNH] = useState("")
  const [catCNH, setCatCNH] = useState("")
  const [vencCNH, setVencCNH] = useState("")
  const [formData, setFormData] = useState({})
  const [modal, setModal] = useState(false)
  const curriculoCollection = collection(db, "curriculos")
  const usersCollection = collection(db, "users")

  const onSubmit = async (data) => {

 
    
    
    await setDoc(doc(curriculoCollection, props.user.uid), {
      useruid: props.user.uid,
      nome: formData.nome,
   
      nascimento: formData.nascimento,
      estadoCivil: formData.estadoCivil,
      celular: formData.celular,
      telefone: formData.telefone,
      cpf: formData.documento,
      rg: formData.rg,
      cnh: formData.cnh,
      numCNH: numCNH,
      catCNH: catCNH,
      vencCNH: vencCNH,
      atuacao1: formData.atuacao1,
      atuacao2: formData.atuacao2,
      atuacao3: formData.atuacao3,
      cursoAtivo: formData.cursoAtivo,
      rua: data.rua,
      complemento: data.complemento,
      cidade: data.cidade,
      numero: data.numero,
      bairro: data.bairro,  
      escolas: [],
      cexperiencias: [],
      cargosExp: [],
      cursos: [],
      estado: data.estado,
      email: props.user.email
      
    })
    await setDoc(doc(usersCollection, props.user.uid), {
      nome: formData.nome,
      vagasC: [],
      novidade: [],
      cpf: formData.documento,
      curriculo: true
    }, {merge: true}).then(()=>{
      setCadastrado(true);
      setModal(true)
    
    }

    )

    
    
  }
  const checkAdress = (e)=>{
      const cep = e.target.value.replace(/\D/g, '');
      fetch(`https://viacep.com.br/ws/${cep}/json/`).then(res => res.json()).then(data =>{
        setValue('rua', data.logradouro)
        setValue('bairro', data.bairro)
        setValue('cidade', data.localidade)
        setValue('estado', data.uf)
      })

      
    }
    const mascara = (e, data) => {
      setValue(e.target.name, mask(e.target.value, data))
    }

    const continuar = (data)=> {
      setFormData({...formData, ...data})
      setStep(1)
       
          }
  return (
    <>
    {modal && <ModalCurriculo  setModal={setModal} setTemCurriculo={props.setTemCurriculo} setNewUser={props.setNewUser} />}
    <Container>
      <Content>
        
        {step == 0 && 
        
        <>
        <form onSubmit={handleSubmit(continuar)}>
        <Message>{props.nome}, seja bem vindo(a) a central de Recursos Humanos do Grupo Metalúrgica Vulcano! <br></br>
Vamos precisar de suas informações - Preencha seus dados pessoais 
</Message>
        <FormContainer tw="bg-secondary-900">
          
          <div tw="mx-auto max-w-5xl">
            <h2>Dados Pessoais</h2>
            
          
            
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Nome Completo</Label>
                    <Input {...register("nome")} disabled={cadastrado} id="name-input" type="text" name="nome" placeholder="Nome" />
                    <Errors>{errors?.nome?.message}</Errors>
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Estado Civil</Label>
                    <Select id="name-input" {...register("estadoCivil")}>
                                     <option value="" disabled selected >Selecione</option>
                                     <option style={{color: "black"
                                    }} >Solteiro</option>
                                    <option style={{color: "black"
                                    }}>Casado</option>
                                    <option style={{color: "black"
                                    }}>União Estável</option>
                                    <option style={{color: "black"
                                    }}>Separado</option>
                                    <option style={{color: "black"
                                    }}>Viúvo</option>
                                    <option style={{color: "black"
                                    }}>Desquitado</option>
                                    <option style={{color: "black"
                                    }}>Divorciado</option>
                                    <option style={{color: "black"
                                    }}>Outro</option>
                                  </Select>
                                  <Errors>{errors?.estadoCivil?.message}</Errors>

                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">CPF</Label>
                    <Input {...register("documento", {onChange: (e) => {mascara(e, "999.999.999-99")}})} disabled={cadastrado} id="name-input" type="text" name="documento" placeholder="CPF" />
                    <Errors>{errors?.documento?.message}</Errors>

                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">Cargo/Área de Atuação desejado</Label>
                    <Select id="name-input" {...register("atuacao1")}>
                                     <option value="" disabled selected >Selecione</option>
                                     <option style={{color: "black" }} >FISCAL </option>
                                      <option style={{color: "black" }} >FINANCEIRO</option>
                                      <option style={{color: "black" }} >SOLDADOR TIG</option>
                                      <option style={{color: "black" }} >SOLDADOR MIG/MAG</option>
                                      <option style={{color: "black" }} >MECÂNICO DE MANUTENÇÃO</option>
                                      <option style={{color: "black" }} >MECÂNICO DE MONTAGEM</option>
                                      <option style={{color: "black" }} >RECURSOS HUMANOS</option>
                                      <option style={{color: "black" }} >SEGURANÇA DO TRABALHO</option>
                                      <option style={{color: "black" }} >ELETRICISTA</option>
                                      <option style={{color: "black" }} >QUALIDADE</option>
                                      <option style={{color: "black" }} >CONTROLADORIA</option>
                                      <option style={{color: "black" }} >ENGENHARIA</option>
                                      <option style={{color: "black" }} >COMPRAS</option>
                                      <option style={{color: "black" }} >ALMOXARIFADO</option>
                                      <option style={{color: "black" }} >LOGÍSTICA</option>
                                      <option style={{color: "black" }} >LIMPEZA</option>
                                      <option style={{color: "black" }} >CONSTRUÇÃO CIVIL</option>
                                      <option style={{color: "black" }} >TRANSPORTE / MOTORISTA</option>
                                      <option style={{color: "black" }} >AJUDANTE</option>
                                      <option style={{color: "black" }} >ESMERILHADOR</option>
                                      <option style={{color: "black" }} >OPERADOR DE EMPILHADEIRA</option>
                                      <option style={{color: "black" }} >OPERADOR DE PONTE ROLANTE</option>
                                      <option style={{color: "black" }} >OPERADOR DE MANDRILHADORA</option>
                                      <option style={{color: "black" }} >PINTOR DE VEÍCULOS</option>
                                      <option style={{color: "black" }} >MAÇARIQUEIRO</option>
                                      <option style={{color: "black" }} >OPERADOR DE MÁQUINAS OPERATRIZES</option>
                                      <option style={{color: "black" }} >DESENHISTA PROJETISTA</option>
                                  </Select>
                                  <Errors>{errors?.atuacao1?.message}</Errors>

                  </InputContainer>
                </Column>
                <Column>
                 
                  <InputContainer>
                    <Label htmlFor="email-input">Celular</Label>
                    <Input id="email-input" disabled={cadastrado} {...register("celular", {onChange: (e) => {mascara(e, "(99) 99999-9999")}})} type="text" name="celular" placeholder="Celular" />
                    <Errors>{errors?.celular?.message}</Errors>

                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">RG</Label>
                    
                    <Input id="email-input" disabled={cadastrado} {...register("rg", {onChange: (e) => {mascara(e, "99.999.999-9")}})} type="text" name="rg" placeholder="RG" />
                    <Errors>{errors?.rg?.message}</Errors>

                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">Cargo/Área de Atuação secundário</Label>
                    <Select id="name-input" {...register("atuacao2")}>
                                     <option value="" disabled selected >Selecione</option>
                                     <option style={{color: "black" }} >FISCAL </option>
                                      <option style={{color: "black" }} >FINANCEIRO</option>
                                      <option style={{color: "black" }} >SOLDADOR TIG</option>
                                      <option style={{color: "black" }} >SOLDADOR MIG/MAG</option>
                                      <option style={{color: "black" }} >MECÂNICO DE MANUTENÇÃO</option>
                                      <option style={{color: "black" }} >MECÂNICO DE MONTAGEM</option>
                                      <option style={{color: "black" }} >RECURSOS HUMANOS</option>
                                      <option style={{color: "black" }} >SEGURANÇA DO TRABALHO</option>
                                      <option style={{color: "black" }} >ELETRICISTA</option>
                                      <option style={{color: "black" }} >QUALIDADE</option>
                                      <option style={{color: "black" }} >CONTROLADORIA</option>
                                      <option style={{color: "black" }} >ENGENHARIA</option>
                                      <option style={{color: "black" }} >COMPRAS</option>
                                      <option style={{color: "black" }} >ALMOXARIFADO</option>
                                      <option style={{color: "black" }} >LOGÍSTICA</option>
                                      <option style={{color: "black" }} >LIMPEZA</option>
                                      <option style={{color: "black" }} >CONSTRUÇÃO CIVIL</option>
                                      <option style={{color: "black" }} >TRANSPORTE / MOTORISTA</option>
                                      <option style={{color: "black" }} >AJUDANTE</option>
                                      <option style={{color: "black" }} >ESMERILHADOR</option>
                                      <option style={{color: "black" }} >OPERADOR DE EMPILHADEIRA</option>
                                      <option style={{color: "black" }} >OPERADOR DE PONTE ROLANTE</option>
                                      <option style={{color: "black" }} >OPERADOR DE MANDRILHADORA</option>
                                      <option style={{color: "black" }} >PINTOR DE VEÍCULOS</option>
                                      <option style={{color: "black" }} >MAÇARIQUEIRO</option>
                                      <option style={{color: "black" }} >OPERADOR DE MÁQUINAS OPERATRIZES</option>
                                      <option style={{color: "black" }} >DESENHISTA PROJETISTA</option>
                                  </Select>
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Data de Nascimento</Label>
                    <Input id="name-input" disabled={cadastrado} {...register("nascimento", {onChange: (e) => {mascara(e, "99/99/9999")}})} type="text" name="nascimento" placeholder="Nascimento" />
                    <Errors>{errors?.nascimento?.message}</Errors>

                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Telefone</Label>
                    <Input id="email-input" disabled={cadastrado} type="text" {...register("telefone", {onChange: (e) => {mascara(e, "(99) 9999-99999")}})} name="telefone" placeholder="Telefone" />
                  </InputContainer>
                  <InputContainer>
                  <Label>Possui CNH?</Label>
                    <LabelRadio htmlFor="name-input">
                    <Radio {...register("cnh")} disabled={cadastrado} id="name-input" type="radio" value={true} />Sim</LabelRadio>
                    <LabelRadio htmlFor="name-input">
                    <Radio {...register("cnh")} disabled={cadastrado} id="name-input" type="radio" value={false}  />Não</LabelRadio>
                    <Errors>{errors?.cnh?.message}</Errors>

                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">Cargo/Área de Atuação terciário</Label>
                    <Select id="name-input" {...register("atuacao3")}>
                                     <option value="" disabled selected >Selecione</option>
                                     <option style={{color: "black" }} >FISCAL </option>
                                      <option style={{color: "black" }} >FINANCEIRO</option>
                                      <option style={{color: "black" }} >SOLDADOR TIG</option>
                                      <option style={{color: "black" }} >SOLDADOR MIG/MAG</option>
                                      <option style={{color: "black" }} >MECÂNICO DE MANUTENÇÃO</option>
                                      <option style={{color: "black" }} >MECÂNICO DE MONTAGEM</option>
                                      <option style={{color: "black" }} >RECURSOS HUMANOS</option>
                                      <option style={{color: "black" }} >SEGURANÇA DO TRABALHO</option>
                                      <option style={{color: "black" }} >ELETRICISTA</option>
                                      <option style={{color: "black" }} >QUALIDADE</option>
                                      <option style={{color: "black" }} >CONTROLADORIA</option>
                                      <option style={{color: "black" }} >ENGENHARIA</option>
                                      <option style={{color: "black" }} >COMPRAS</option>
                                      <option style={{color: "black" }} >ALMOXARIFADO</option>
                                      <option style={{color: "black" }} >LOGÍSTICA</option>
                                      <option style={{color: "black" }} >LIMPEZA</option>
                                      <option style={{color: "black" }} >CONSTRUÇÃO CIVIL</option>
                                      <option style={{color: "black" }} >TRANSPORTE / MOTORISTA</option>
                                      <option style={{color: "black" }} >AJUDANTE</option>
                                      <option style={{color: "black" }} >ESMERILHADOR</option>
                                      <option style={{color: "black" }} >OPERADOR DE EMPILHADEIRA</option>
                                      <option style={{color: "black" }} >OPERADOR DE PONTE ROLANTE</option>
                                      <option style={{color: "black" }} >OPERADOR DE MANDRILHADORA</option>
                                      <option style={{color: "black" }} >PINTOR DE VEÍCULOS</option>
                                      <option style={{color: "black" }} >MAÇARIQUEIRO</option>
                                      <option style={{color: "black" }} >OPERADOR DE MÁQUINAS OPERATRIZES</option>
                                      <option style={{color: "black" }} >DESENHISTA PROJETISTA</option>
                                  </Select>
                  </InputContainer>
                  
                  
                </Column>
                
              </TwoColumn>
              
              {watch("cnh") == "true" ? (
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Número da CNH</Label>
                    <Input {...register("numCNH")} disabled={cadastrado} id="name-input" type="text" name="numCNH" placeholder="Número da CNH" />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Vencimento da CNH</Label>
                    <Input {...register("vencCNH", {onChange: (e) => {mascara(e, "99/99/9999")}})} disabled={cadastrado} id="name-input" type="text" name="vencCNH" placeholder="Vencimento da CNH" />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Categoria da CNH</Label>
                    <Select id="name-input" {...register("catCNH")}>
                                     <option value="" disabled selected >Selecione</option>
                                     <option style={{color: "black" }} >A </option>
                                      <option style={{color: "black" }} >B</option>
                                      <option style={{color: "black" }} >C</option>
                                      <option style={{color: "black" }} >D</option>
                                      <option style={{color: "black" }} >E</option>
                                      <option style={{color: "black" }} >A e B</option>
                                      <option style={{color: "black" }} >A e C</option>
                                      <option style={{color: "black" }} >A e D</option>
                                      <option style={{color: "black" }} >A e E</option>
                
                                  </Select>
                  </InputContainer>
                </Column>
              </TwoColumn>):(<><Input {...register("numCNH",{value: ""})} disabled={cadastrado}  type="hidden" name="numCNH"  />
              <Input {...register("vencCNH",{value: ""})} disabled={cadastrado} id="name-input" type="hidden" name="vencCNH" />
              <Input {...register("catCNH",{value: ""})} disabled={cadastrado} id="name-input" type="hidden" name="catCNH" /></>)
              }
              <TwoColumn>
                <ColumnFull>
                  <InputContainer>
                  <Label>Possui curso ativo de CBSP  e HUET?</Label>
                    <LabelRadio htmlFor="name-input">
                    <Radio {...register("cursoAtivo")} disabled={cadastrado} id="name-input" type="radio" value={true} />Sim</LabelRadio>
                    <LabelRadio htmlFor="name-input">
                    <Radio {...register("cursoAtivo")} disabled={cadastrado} id="name-input" type="radio" value={false}  />Não</LabelRadio>
                  </InputContainer>
                </ColumnFull>
                
              </TwoColumn>

              <SubmitButton tw="text-secondary-900" type="submit"  value="Submit">Continuar</SubmitButton>
            
          </div>
          <SvgDotPattern1 />
        </FormContainer>
</form>

</>}
       {step == 1 && 
       <>
       <form onSubmit={handleSubmit(onSubmit)}>
       <Message>Perfeito, agora vamos precisar de seu endereço:</Message>
       <FormContainer tw="bg-secondary-900">
          <div tw="mx-auto max-w-5xl">
            <h2>Endereço</h2>
            
              <TwoColumn>
              <Column>
              <InputContainer>
                    <Label htmlFor="name-input">CEP</Label>
                    <Input id="name-input" disabled={cadastrado} type="text" {...register("cep", {onBlur: checkAdress, onChange: (e) => {mascara(e, "99999-999")} })} name="cep" placeholder="CEP" />
                    <Errors>{errors?.cep?.message}</Errors>

                  </InputContainer>
                  
                  <InputContainer>
                    <Label htmlFor="email-input">Número</Label>
                    <Input id="email-input" disabled={cadastrado} {...register("numero")} type="text" name="numero" placeholder="Número" />
                    <Errors>{errors?.numero?.message}</Errors>

                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">Complemento</Label>
                    <Input id="name-input" disabled={cadastrado} type="text" {...register("complemento")} name="complemento" placeholder="Complemento" />
          

                  </InputContainer>
                
                </Column>
                <Column>
                <InputContainer>
                    <Label htmlFor="name-input">Logradouro</Label>
                    <Input id="name-input" disabled={cadastrado} {...register("rua")} type="text" name="rua" placeholder="Logradouro" />
                    <Errors>{errors?.rua?.message}</Errors>

                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Bairro</Label>
                    <Input id="email-input" disabled={cadastrado} {...register("bairro")} type="text" name="bairro" placeholder="Bairro" />
                    <Errors>{errors?.bairro?.message}</Errors>

                  </InputContainer>
                 
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Cidade</Label>
                    <Input id="name-input" disabled={cadastrado} {...register("cidade")} type="text" name="cidade" placeholder="Cidade" />
                    <Errors>{errors?.cidade?.message}</Errors>

                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Estado</Label>
                    <Input id="email-input" disabled={cadastrado} {...register("estado")} type="text" name="estado" placeholder="Estado" />
                    <Errors>{errors?.estado?.message}</Errors>

                  </InputContainer>
                  
                </Column>
              </TwoColumn>

              <SubmitButton tw="text-secondary-900" type="submit" disabled={cadastrado} value="Submit">Continuar</SubmitButton>

              {cadastrado && <div>Cadastrado com sucesso</div>}
          </div>
          <SvgDotPattern2 />
        </FormContainer></form> </>}
        
      </Content>
    </Container>
    </>
  );
};
