import React, { useEffect, useState } from "react";
import { auth } from "services/firebaseConfig";
import { Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import tw from "twin.macro";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/slide2.jpg";
import logo from "images/logo.png";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import ModalSenha from "components/features/ModalSenha";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-20 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-2xl font-light`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;



const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-orange-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Faça seu Login",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Login com Google",
      url: "https://google.com"
    }
  ],
  submitButtonText = "Login",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "#",
  privacyPolicyUrl = "#",
  signInUrl = "#"
}) => {
  const navigate = useNavigate();
    const { register, handleSubmit, getValues } = useForm();
    const [modal, setModal] = useState(false);
    const [erro, setErro] = useState("");
    const[erroSenha, setErroSenha] = useState("");

    const onSubmit = data => {signInWithEmailAndPassword(data.email, data.password);}
    const [
      signInWithEmailAndPassword,
      user,
      loading,
      error,
    ] = useSignInWithEmailAndPassword(auth);

    const resetPassword = ()=>{
      sendPasswordResetEmail(auth, getValues("email"))
      .then(() => {
        setModal(true)
        })
      .catch((error) => {
        console.log(error.code);
        console.log(error.message);

        if(error.code == "auth/missing-email")
        setErroSenha("Por favor, insira um email")
       else if(error.code == "auth/invalid-email")
        setErroSenha("Por favor, insira um email válido")
        else if(error.code == "auth/user-not-found")
        setErroSenha("Não foi encontrado um usuário com esse e-mail")
        // ..
      });
    }

    useEffect(()=>{
 if (error) {
  if(error.message == "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).")
  setErro("O Acesso a essa conta foi desabilitada por muitas tentativas falhas de login, favor entrar em contato")
  else if(error.message == "Firebase: Error (auth/email-already-in-use).")
  setErro("E-mail já cadastrado")
  else if(error.message == "Firebase: Password should be at least 6 characters (auth/weak-password).")
  setErro("A senha deve possuir no mínomo 6 caracteres")
  else
setErro(error.message);
    }
    
    if (user) {
     console.log(user.user.uid)
     navigate("/home")
    }
    }, [error] [user])
if (loading) {
      console.log("Loading...");
    }
   
    

    return(
      <>
      {modal && <ModalSenha setModal={setModal}></ModalSenha>}
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              
              
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Input {...register("email")} type="email" placeholder="Email" />
                <p tw="mt-3 text-xs text-red-600 text-center">{erroSenha}</p>
                <Input {...register("password")} type="password" placeholder="Senha" />
                
                <SubmitButton type="submit">
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
                
                <p tw="mt-3 text-xs text-red-600 text-center">{erro}</p>
                <p tw="mt-8 text-sm text-gray-600 text-center">
                  
                  <a href="#" onClick={()=>{resetPassword()}} tw="border-b border-gray-500 border-dotted">
                    Esqueci minha senha
                  </a></p>

                <p tw="mt-8 text-sm text-gray-600 text-center">
                  Não tem uma conta?{" "}
                  <Link to="/cadastro" tw="border-b border-gray-500 border-dotted">
                    Cadastro
                  </Link>
                </p>
              </Form>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer style={{backgroundImage: `url(${illustration})`,
                                        backgroundSize: `cover`,
                                        backgroundPosition: `center`}}>
          
        </IllustrationContainer>
      </Content>
    </Container>
  </AnimationRevealPage></>
)};
