import React, { useState, props } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import uuid from "react-uuid";
import { useForm } from "react-hook-form";
import { collection, addDoc, setDoc, doc, arrayUnion } from "firebase/firestore";
import { db } from "services/firebaseConfig";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import ModalFormacao from "components/features/ModalFormacao";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto pb-20 lg:pb-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 mt-20 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-2xl sm:text-2xl font-light`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-white text-base font-medium tracking-wide border-b-2 py-2 text-white hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-white`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-4/12 flex flex-col pl-10`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-64 mt-6 mr-10 py-3 bg-gray-100  rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`
const SvgDotPattern2 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-secondary-500 fill-current w-24`

export default ({userUid, setCadastroFromacao, setCadastrado2}) => {
  const { register, handleSubmit, reset } = useForm();
  const [cadastrado, setCadastrado] = useState(false);
  const academicaCollection = collection(db, "curriculos", userUid, "academica")
  const usersCollection = collection(db, "curriculos")
  const [modal, setModal] = useState(false)

  const onSubmit = async (data) => {
    

    const uid = uuid();
    await setDoc(doc(academicaCollection, uid), {
      academicaUid: uid,
      curso: data.curso,
      termino: data.termino,
      escola: data.escola,
      inicio: data.inicio,
      
      
    })
    await setDoc(doc(usersCollection, userUid), {
      cursos : arrayUnion(data.curso),
      escolas : arrayUnion(data.escola),
      
    }, {merge: true})
    
    setModal(true)
  }
  return (
    <>
{modal && <ModalFormacao setModal={setModal} palavra="Formação Acadêmica"  setCadastrado={setCadastrado} setCadastro={setCadastroFromacao} setCadastrado2={setCadastrado2} reset={reset}></ModalFormacao>}
    <Container>

      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer tw="bg-gray-600">
          <div tw="mx-auto max-w-5xl">
          <h2>Cadastrar Formação Acadêmica</h2>
            
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Curso</Label>
                    <Input {...register("curso")} disabled={cadastrado} id="name-input" type="text" name="curso" placeholder="Nome do Curso" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">Data de Término</Label>
                    <Input {...register("termino")} disabled={cadastrado} id="name-input" type="text" name="termino" placeholder="Data de Término" />
                  </InputContainer>
                  </Column>
                  <Column>
                  <InputContainer>
                    <Label htmlFor="email-input">Escola</Label>
                    <Input {...register("escola")} disabled={cadastrado} id="email-input" type="text" name="escola" placeholder="Escola" />
                  </InputContainer>
                  </Column><Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Data de Início</Label>
                    <Input {...register("inicio")} disabled={cadastrado} id="name-input" type="text" name="inicio" placeholder="Data de Início" />
                  </InputContainer>
                
                  
                  
                </Column>
               
                
              </TwoColumn>

              
              
              {!cadastrado && (<>
              <SubmitButton tw="text-gray-900" type="submit"  value="Submit">Cadastrar Formação</SubmitButton>
              <SubmitButton tw="text-gray-900" type="buttont" onClick={()=>{setCadastroFromacao(false)}}  value="Submit">Voltar</SubmitButton>
              </>)}
              
              {cadastrado && <>
              <p><br></br>Cadastrado com sucesso!</p>
              <SubmitButton tw="text-gray-900" type="submit" onClick={()=>{setCadastroFromacao(false)}}  value="Submit">Ok</SubmitButton>
              </>}
            
          </div>
          <SvgDotPattern1 />
        </FormContainer>



       
          
        </form>
      </Content>
    </Container>
    </>
  );
};
