import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { db } from "services/firebaseConfig";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { useNavigate } from "react-router-dom";
import { SectionDescription } from "components/misc/Typography.js";
import ModalText from "./ModalText";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { doc, deleteDoc } from "firebase/firestore";


const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ModalWrapper = styled.div`
  ${tw`w-full h-full bg-black fixed top-0 left-0 opacity-75 z-40`}
`;
const Iframe = styled.iframe`
  ${tw`w-full h-full overflow-visible`}
`;
const ModalContent = styled.div`
  ${tw`w-full h-full p-10 overflow-scroll`}
`;
const Modal = styled.div`
  ${tw`w-full h-full rounded-2xl overflow-hidden  bg-gray-200  inset-1/2 opacity-100  p-10 text-gray-600 text-center`}
  span{
    ${tw`text-black   text-justify  leading-6 block`}
  }
  h2{
    ${tw`text-black   mb-10  leading-6 block`}
  }
`;
const ModalWrapper2 = styled.div`
  ${tw` w-full max-w-block h-screen rounded-2xl    fixed inset-1/2 opacity-100 z-50 -translate-x-1/2 -translate-y-1/2 py-20 text-gray-600 text-center`}
  
`;

const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;
const SubmitButton = tw.button`w-full mt-4 text-primary-600 sm:w-32 py-3 bg-gray-100  rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;
const SubmitButton2 = tw.button`w-full mt-4 block ml-2 text-primary-600 sm:w-32 py-3 bg-gray-100  rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;




const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;


export default ({
    setModal, setDataCheck, setHoraCheck, register, check, setCheck, handleSubmit, onSubmit
    
 
}) => {

  const navigate =  useNavigate()
  const [deletado, setDeletado] = useState(false);

  
  const action = ()=>{
    handleSubmit(onSubmit)
    setModal(false)
  
  }

    
    
    
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <>
    <ModalWrapper>
        
    </ModalWrapper>
    <ModalWrapper2>
       
    <Modal><ModalContent>
      <ModalText></ModalText>


      <input {...register("chooseCB", {
                  onChange: ()=>{
                    const data = new Date()
                    const dia = String(data.getDate()).padStart(2, '0')
                    const mes = String(data.getMonth() + 1).padStart(2, '0')
                    const ano = String(data.getFullYear())
                    const horas = String(data.getHours()).padStart(2, '0')
                    const minutos = String(data.getMinutes()).padStart(2, '0')
                    const segundos = String(data.getSeconds()).padStart(2, '0')

                    setDataCheck(`${dia}/${mes}/${ano}`)
                    setHoraCheck(`${horas}:${minutos}:${segundos}`)

                    if (check) {
                      setCheck(false)
                    } else {
                      setCheck(true)
                    }
                    
                  }
                })} type="checkbox" tw="mt-3" />
                <label tw="mt-10 ml-2 text-xs text-gray-600 text-center">
                Ao preencher este formulário, concordo em receber comunicações da Metalúrgica Vulcano Ltda de acordo com meus interesses e concordo com sua {" "}
                  <a href="https://www.manpowergroup.com.br/wps/portal/manpowergroup/br/politica-de-privacidade"  tw="border-b border-gray-500 border-dotted">
                    Política de privacidade
                  </a>{" "}
                </label>
       <>
        <SubmitButton2  type="button" onClick={()=>{action()}} >Fechar</SubmitButton2></></ModalContent>
        </Modal></ModalWrapper2></>
  );
};
